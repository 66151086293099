.new-cost {
  background-color: #aac4d1;
  padding: 1rem;
  margin: 2rem auto;
  width: 70rem;
  max-width: 95%;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
}

.new-cost button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 1px solid #2a5b84;
  background-color: #2a5b84;
  color: #fefae1;
  border-radius: 10px;
  margin-right: 3px;
}

.new-cost button:hover,
.new-cost button:active {
  background-color: #2a5c84b2;
  border-color: #2a5c84b2;
}