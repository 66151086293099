.cost-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    margin: 1rem 0;
    background-color: #bd8025;
    border-radius: 10px;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.25);
  }
  
  .cost-item__description {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
    flex-flow: column-reverse;
    justify-content: flex-start;
    flex: 1;
  }
  
  .cost-item h2 {
    color: #3a3a3a;
    font-size: 1rem;
    flex: 1;
    margin: 0 1rem;
    color: #fefae1;
  }
  
  .cost-item__price {
    font-size: 1rem;
    font-weight: bold;
    color: #fefae1;
    background-color: #2a5b84;
    border: 1px solid white;
    padding: 0.5rem;
    border-radius: 12px;
  }
  
  @media (min-width: 580px) {
    .cost-item__description {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
    }
  
    .cost-item__description h2 {
      font-size: 1.25rem;
    }
  
    .cost-item__price {
      font-size: 1.25rem;
      padding: 0.5rem 1.5rem;
    }
  }